import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import ROUTES from 'config/routes';
import { DEVICE } from 'config/theme';
import Trans from 'next-translate/Trans';
import Image from 'next/image';
import Link from 'next/link';
import LinkButton from 'shared/components/LinkButton';
import useIsInViewport from 'shared/hooks//useIsInViewport';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import useTranslation from 'shared/hooks/useTranslation';

import { animated, useSpring } from '@react-spring/web';

const Section = styled.section`
  align-items: center;
  background: linear-gradient(0deg, #19171d 0%, #000000 82.22%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  overflow: hidden;
  padding: 1.6rem 0 0 0;
  max-width: 100vw;
`;

const gradient = keyframes`
  {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-bottom: 4rem;

  ${({ theme }) => theme.MEDIA.XXL} {
    max-width: 75%;
  }
`;

const AnimatedGradientHeader = styled(animated.h2)`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(263.37deg, #fccc51 0.21%, #fd6e40 52.03%, #e75b95 103.85%);
  background-size: 150%;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 8rem;
  font-weight: 700;
  text-align: center;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3rem;
`;

const AnimatedSubHeader = styled(animated.span)`
  color: white;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 4rem;
  opacity: 0;
  text-align: center;
  margin: 0 auto;
  margin-top: 3.2rem;
  max-width: 95rem;
`;

const Comparison = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  row-gap: 4rem;
  height: initial;
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  height: inherit;
`;

const LeftSide = styled(animated(Row))`
  grid-template-columns: 3fr minmax(40rem, 2fr);
`;

const RightSide = styled(animated(Row))`
  grid-template-columns: minmax(40rem, 2fr) 3fr;
`;

const LeftImage = styled.div`
  position: relative;
  height: inherit;
  transform: scale(1.7);
  z-index: 2;
`;

const RightImage = styled.div`
  height: inherit;
  position: relative;
  transform: scale(1.7);
`;

const Card = styled(animated.div)`
  background-color: white;
  color: #35353f;
  padding: 4rem;
  opacity: 0;

  ul {
    margin-left: 3.2rem;
  }

  h4 {
    color: #a9a9aa;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.7rem;
    margin-top: 3.5rem;
    margin-bottom: 3rem;
  }

  h3 {
    margin-top: 0.2rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.8rem;
  }

  li {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1.6rem;

    div {
      transform: translateY(-0.6rem);
    }
  }
`;

const LeftCard = styled(Card)`
  ul {
    list-style: square url('/img/home/comparison/true.png');
  }
`;

const RightCard = styled(Card)`
  ul {
    list-style: square url('/img/home/comparison/false.png');
  }
`;

const ButtonWrapper = styled(animated.div)`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: center;
  margin-bottom: 6rem;
`;

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 4rem;
  padding: 1.2rem 6rem;
  width: fit-content;
`;

function CreateAccountSection(): JSX.Element {
  const { t, lang } = useTranslation('homePage');

  const { isMatch: isLargeDevice, mediaLoaded } = useMediaQuery(DEVICE.XXL);

  const threshold = { threshold: 0 };
  const sectionRef = useRef(null);
  const isSectionInView = useIsInViewport(sectionRef, threshold);

  const [isAnimated, setAnimated] = useState(false);

  const [{ translateLeftX }, setLeftComparisonAnimation] = useSpring(() => ({
    translateLeftX: -13,
  }));

  const [{ translateRightX }, setRightComparisonAnimation] = useSpring(() => ({
    translateRightX: 13,
  }));

  const [{ headerOpacity }, setHeaderAnimation] = useSpring(() => ({
    headerOpacity: 0,
  }));

  const [{ subHeaderOpacity }, setSubHeaderAnimation] = useSpring(() => ({
    subHeaderOpacity: 0,
  }));

  const [{ rightComparisonTextOpacity }, setRightComparisonTextAnimation] = useSpring(() => ({
    rightComparisonTextOpacity: 0,
  }));

  const [{ leftComparisonTextOpacity }, setLeftComparisonTextAnimation] = useSpring(() => ({
    leftComparisonTextOpacity: 0,
  }));

  const [{ buttonOpacity }, setButtonAnimation] = useSpring(() => ({
    buttonOpacity: 0,
  }));

  useEffect(() => {
    if (isSectionInView && !isAnimated) {
      setAnimated(true);
      setHeaderAnimation({
        headerOpacity: 1,
        config: {
          tension: 120,
          friction: 14,
        },
      });
      setSubHeaderAnimation({
        subHeaderOpacity: 1,
        delay: 800,
        config: {
          tension: 70,
          friction: 140,
          mass: 2,
        },
      });
      setRightComparisonAnimation({
        translateRightX: 1,
        config: {
          tension: 340,
          friction: 75,
        },
      });
      setRightComparisonTextAnimation({
        rightComparisonTextOpacity: 1,
        delay: 1600,
        config: {
          tension: 120,
          friction: 140,
          mass: 1,
        },
      });
      setLeftComparisonAnimation({
        translateLeftX: -1,
        config: {
          tension: 340,
          friction: 75,
        },
      });
      setLeftComparisonTextAnimation({
        leftComparisonTextOpacity: 1,
        delay: 1600,
        config: {
          tension: 120,
          friction: 140,
          mass: 1,
        },
      });
      setButtonAnimation({
        buttonOpacity: 1,
        delay: 2000,
        config: {
          tension: 120,
          friction: 140,
          mass: 1,
        },
      });
    }
  }, [
    setButtonAnimation,
    isSectionInView,
    setRightComparisonAnimation,
    setLeftComparisonAnimation,
    setHeaderAnimation,
    setSubHeaderAnimation,
    setRightComparisonTextAnimation,
    setLeftComparisonTextAnimation,
    setAnimated,
    mediaLoaded,
    isLargeDevice,
    isAnimated,
    subHeaderOpacity,
  ]);

  return (
    <Section ref={sectionRef}>
      <HeaderWrapper>
        <AnimatedGradientHeader style={{ opacity: headerOpacity?.interpolate((o) => o) }}>
          {t('comparisonSection.advantage')}
        </AnimatedGradientHeader>
        <AnimatedSubHeader style={{ opacity: subHeaderOpacity?.interpolate((o) => o) }}>
          {t('comparisonSection.comparison')}
        </AnimatedSubHeader>
      </HeaderWrapper>
      <Comparison>
        <LeftSide style={{ transform: translateLeftX?.interpolate((v) => `translateX(${v}vw)`) }}>
          <LeftImage>
            <Image
              src={`/img/home/comparison/${lang === 'pl' ? 'pl' : 'en'}/trustmate-hand.png`}
              alt=""
              quality={100}
              lazyBoundary="200px"
              layout="fill"
              objectFit="contain"
              objectPosition="center bottom"
            />
          </LeftImage>
          <LeftCard style={{ opacity: leftComparisonTextOpacity?.interpolate((o) => o) }}>
            <Image
              src="/img/logos/logo-trustmate-2022-light.svg"
              alt=""
              quality={100}
              lazyBoundary="200px"
              height={50}
              width={220}
            />
            <h4>{t('comparisonSection.qualityReview')}</h4>
            <ul>
              <li>
                <div>{t('comparisonSection.copywrite')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.decision')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.keyPhrases')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.websiteTraffic')}</div>
              </li>
              <li>
                <div>
                  <Trans
                    i18nKey="homePage:comparisonSection.characters"
                    values={{
                      charactersCount: 200,
                    }}
                    fallback="homePageEN:comparisonSection.characters"
                  />
                </div>
              </li>
            </ul>
          </LeftCard>
        </LeftSide>
        <RightSide style={{ transform: translateRightX?.interpolate((v) => `translateX(${v}vw)`) }}>
          <RightCard style={{ opacity: rightComparisonTextOpacity?.interpolate((o) => o) }}>
            <h3>{t('comparisonSection.reviewFromWeb')}</h3>
            <h4>{t('comparisonSection.shortReviews')}</h4>
            <ul>
              <li>
                <div>{t('comparisonSection.fewCharacters')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.searchNegativeReviews')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.notKeyPhrases')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.notAttractiveForGoogle')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.noFeedback')}</div>
              </li>
            </ul>
          </RightCard>
          <RightImage>
            <Image
              src={`/img/home/comparison/${lang === 'pl' ? 'pl' : 'en'}/competition-hand.png`}
              alt=""
              quality={100}
              lazyBoundary="200px"
              layout="fill"
              objectFit="contain"
              objectPosition="center bottom"
            />
          </RightImage>
        </RightSide>
        <ButtonWrapper style={{ opacity: buttonOpacity?.interpolate((o) => o) }}>
          <Link href={ROUTES.REGISTER}>
            <StyledLinkButton
              onClick={
                process.env.NEXT_PUBLIC_APP_ENV === 'prod'
                  ? "ga('send', 'event', 'button - redirect to register', 'click', 'page - homePage', 'section - comparison section');"
                  : ''
              }
            >
              {t('navbar.freeTrial', 'common')}
            </StyledLinkButton>
          </Link>
        </ButtonWrapper>
      </Comparison>
    </Section>
  );
}

export default CreateAccountSection;
